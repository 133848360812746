import { BrowserRouter, Routes, Route} from 'react-router-dom' 
import Home from './pages/home';
import Checkout from './pages/checkout';
import Sidebar from './components/sidebar';
import Raiz from './pages/raiz';


function Rotas(){
    return <>
        
        <BrowserRouter>
            <Sidebar/>  
            <Routes>
                <Route exact path='/' element={<Raiz/>} />          
                <Route exact path='/:url_loja' element={<Home/>} />
                <Route exact path='/:url_loja/checkout' element={<Checkout/>} />                
                {/* <Route exact path='/checkout' element={<Checkout/>} />                 */}
            </Routes>
        </BrowserRouter>        
    </>
     
}

export default Rotas;