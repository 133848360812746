import { createContext, useEffect, useState } from "react";

const LojaContext = createContext({});

function LojaProvider(props){


    const [loja, setLoja] = useState([]);
    const [cart, setCart] = useState([]);
    const [totalCart, setTotalCart] = useState(0);
    const urlServidor = 'http://apicatalogo.onesistema.com.br';
    //const urlServidor = 'http://localhost:9000';
    const [urlLojaState, setUrlLojaState] = useState('');

    function SalvarCart(produtos){
        if (produtos.length > 0){            
            localStorage.setItem(`sessionCart${loja.id}`, JSON.stringify({
                loja_id : loja.id,
                itens: produtos 
            }))
        } else {
            localStorage.removeItem(`sessionCart${loja.id}`);
        }

    }


    function AddItemCart(item){
        setCart([...cart, item]);
        SalvarCart([...cart, item]);
    }
    
    function RemoveItemCart(id){    
        const novoCart = cart.filter((item, index, arr) => {
            return item.id_carrinho !== id;
        });
        setCart(novoCart);
        SalvarCart(novoCart);    
    }
    useEffect(() => {
        const dados = localStorage.getItem(`sessionCart${loja.id}`);        
        if (dados){                    
            setCart(JSON.parse(dados).itens);            
        }

    },[loja])

    useEffect(()=>{
        let soma = cart.reduce((a, b) => a + (b.vl_unit * b.qtde),0 );
        
        setTotalCart(soma);
    }, [cart])

    return <LojaContext.Provider value={{loja, setLoja, cart, setCart, totalCart, setTotalCart, AddItemCart, RemoveItemCart, SalvarCart,
        urlLojaState, setUrlLojaState, urlServidor}}>
        {props.children}
    </LojaContext.Provider>
}

export {LojaContext, LojaProvider};