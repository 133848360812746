import React from 'react';
import ReactDOM from 'react-dom';
import Rotas from './rotas'
import { LojaProvider } from './contexts/loja';

import './styles/global.css';
import { ToastContainer } from 'react-toastify';

ReactDOM.render(

 <LojaProvider>    
    <Rotas/>      
    <ToastContainer position="top-center"
                        autoClose={1500}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss={false}
                        draggable={false}
                        pauseOnHover
                        limit={2}
                        theme="colored"                        
                        />      
 </LojaProvider>
 ,
 document.getElementById('root')
);