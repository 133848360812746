import { useContext } from 'react';
import {LojaContext} from '../contexts/loja';


export function getCurrentDate(separator=''){

    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    
    return `${date}${separator}${month<10?`0${month}`:`${month}`}${separator}${year}`
}

export function getCurrentHour(){

    let newDate = new Date()
    let hora = newDate.getHours();
    let minuto = newDate.getMinutes() + 1;
    
    return `${hora}:${minuto<10?`0${minuto}`:`${minuto}`}`
}


export function EnviarPedidoWhatsApp(pedido_id, dados, url_loja, whatsapp){           
    var email = '';
    var entrega = '';
    let obs = '';
    if (dados.entrega == 1) {
        entrega = 'Entrega';
        var endereco = `Endereço: ${dados.cliente_endereco}\n\n`;
    } else {
        entrega = 'Retirada';
        var endereco = `\n`;

    }

    if (dados.obs != '') {
        obs = `*Obs do pedido: ${dados.obs}*\n\n`;
    } else {
        obs = ``; 
    }

    if (dados.cliente_email != '') {
        email = `Email: ${dados.cliente_email}\n`;
    }    
    var data = getCurrentDate('/');
    var hora = getCurrentHour();

    if (!whatsapp) {
        return
    }
   
    

        var  itensPedido = dados.itens.map((item => {
                return `${("000000"+item.produto_one_id).slice(-6)} ${item.nome}\n`+
                `${item.qtde.toLocaleString('pt-BR', {minimumIntegerDigits: 2})} x ${item.vl_unit.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})} = ${item.vl_total.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}\n`+
                `-----------------\n`;
        }))
        itensPedido= itensPedido.join('');
        
        var texto = `👋 Olá, venho do catálogo http://onecatalogo.ddns.net/${url_loja}\n\n`+

                    `*Pedido Nº: P${("000000"+pedido_id).slice(-6)}*\n`+
                    `🗓️ ${data} ⏰ ${hora}\n\n`+
                    
                    `*Tipo de serviço: ${entrega}*\n\n`+

                    `Nome: ${dados.cliente_nome}\n`+
                    `Telefone: ${dados.cliente_telefone}\n`+
                    `${email}`+
                    `${endereco}`+
                    

                    `*Método de pagamento solicitado: ${dados.especie_pag_nome}*\n\n`+

                    `💲 Custos\n`+
                    `Preço de entrega: à combinar\n`+
                    `*Total a pagar: ${dados.vl_total.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}*\n\n`+

                    `📝 *Itens do Pedido*\n\n`+                    

                    `${itensPedido}\n`+      
                    
                    `${obs}`+
                        
                    `👆 Após enviar o pedido, aguarde que já iremos lhe atender..\n`;
                
        texto = window.encodeURIComponent(texto);
        window.open(`whatsapp://send?phone=55${whatsapp}&text=${texto}`, "_blank")
    
}

