import { useContext, useState } from 'react';
import { LojaContext } from '../../../contexts/loja';
import './style.css';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';

function Produto(props){     
    const {AddItemCart, urlServidor} = useContext(LojaContext);
    const [qtde, setQtde] = useState(1);    

    

    function ClickMenos(){
        qtde > 1 ? setQtde(qtde-1) : setQtde(1);
    }
    function ClickMais(){
        setQtde(qtde+1);
    }
    
    function AddItem(){
     const item = {
            id_carrinho : uuidv4(),
            produto_id : props.produto_id,
            produto_one_id : props.produto_one_id,
            nome: props.nome,
            qtde: qtde,
            vl_unit: props.valor, 
            vl_total : props.valor * qtde,           
            imagem: props.imagem,
            detalhes: []
        }    

        AddItemCart(item);   
        toast.success("Adicionado com sucesso!");   
    }

 

    return <div className="col-sm-6 col-lg-3 mb-2 p-1">  
            <div className='produto'>                              
                {                                      
                    props.imagem == undefined || props.imagem == null || props.imagem == ''  ?  
                    <img className="img-fluid rounded imgProdutoLista" src={process.env.PUBLIC_URL + "/sem_imagem.png"} alt=""/>
                    : <img className="img-fluid rounded imgProdutoLista" src={urlServidor+props.imagem} alt=""/>
                }                            
                    <span className='d-block ms-2 nome-produto'><b>{props.nome}</b></span>                
                <div className='d-flex justify-content-between'>
                    <small className='ms-2'>(cod. {("000000"+props.id).slice(-6)})</small>            
                    <span className='preco me-3'>{props.valor.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</span>
                </div>
                
               
               <div className='row'>
                    <div className='col-12 mt-3 mb-3'>
                        <div className='d-flex justify-content-end'>              
                            <div className='col-6 d-flex justify-content-center'>                              
                                <button onClick={ClickMenos} className='btn btn-outline-secondary me-2'><i className='fas fa-minus'></i></button>
                                <span className='m-2 button-qtd'>{qtde.toLocaleString('pt-BR', {
                                    minimumIntegerDigits: 2
                                })}</span>                            
                                <button onClick={ClickMais} className='btn btn-outline-secondary ms-2'><i className='fas fa-plus'></i></button>                                                                                
                            </div>
                            <div className='col-6 d-grid gap-2 me-1'>
                                <button onClick={AddItem} className='btn btn-secondary'>
                                <i className='fas fa-shopping-bag me-2'/>
                                    <span className='ms-1'>{(props.valor * qtde).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</span>   
                                </button>
                            </div>                    
                            
                        </div>                                            
                    </div>
                </div>

            </div>     
            
        </div>

        
}

export default Produto