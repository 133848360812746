import axios from "axios";

const api = axios.create({
    baseURL: 'https://apicatalogo.onesistema.com.br',
    //baseURL: 'http://localhost:9000',
    auth :{
        username : 'onecat',
        password: '102030'
    }
});

export default api;