
import { useContext, useEffect, useLayoutEffect, useState } from "react";
import { LojaContext } from '../../contexts/loja';
import NavBar from "../../components/navbar";
import './style.css';
import Produto from '../../components/produto/sacola';
import api from "../../services/api";
import { EnviarPedidoWhatsApp } from "../../utils";
import InputMask from 'react-input-mask';
import { toast } from "react-toastify";

function Checkout(){
    
    const {loja, cart, setCart, totalCart, RemoveItemCart} = useContext(LojaContext);
    const [tipoEntrega, setTipoEntrega] = useState(0);
    const [especiesPag, setEspeciesPag] = useState([]);
    const [tipoPagId, setTipoPagId] = useState(0);
    const [tipoPagNome, setTipoPagNome] = useState('');
    const[clienteNome, setClienteNome] = useState('');
    const[clienteTelefone, setClienteTelefone] = useState('');
    const[clienteEndereco, setClienteEndereco] = useState('');
    const[clienteEmail, setClienteEmail] = useState('');
    const[obsPedido, setObsPedido] = useState('');    
    
    function SelecionarTipoEntrega(tipo){
        setTipoEntrega(tipo); 
        if (tipo == 0) {
            document.getElementById("inlineRadioRetirada").checked = true;
            document.getElementById("inlineRadioEntrega").checked = false;
        } else {
            document.getElementById("inlineRadioRetirada").checked = false;
            document.getElementById("inlineRadioEntrega").checked = true;
        }
        

    }

    function SelecionarEspeciePag(){
        if (especiesPag) {
        var selectBox = document.getElementById("selectEspeciePag");
        var selectedValue = selectBox.options[selectBox.selectedIndex].value;
        var Texto = selectBox.options[selectBox.selectedIndex].text; 
        setTipoPagId(selectedValue);        
        setTipoPagNome(Texto);
        // console.log(Texto);
        }
    }

    function FinalizarPedido(){

        if (!clienteNome || clienteNome == '') {
            alert('Nome não preenchido');
            return;            
        }
        if (!clienteTelefone || clienteTelefone == '') {
            alert('Telefone não preenchido');
            return;            
        }

        if ((!clienteEndereco || clienteEndereco == '') && (tipoEntrega == 1)) {
            alert('Endereço não preenchido');
            return;            
        }
        


        var data = {
            loja_id : loja.id,
            cliente_nome : clienteNome,
            cliente_telefone : clienteTelefone,
            cliente_endereco : clienteEndereco,
            cliente_email : clienteEmail,
            obs : obsPedido,
            especie_pag_id : tipoPagId,
            especie_pag_nome : tipoPagNome,
            vl_total : totalCart,
            entrega : tipoEntrega,
            itens : cart
        };     

        api.post(`api/v1/pedidos`, data)
        .then(response =>{ 
            if (response.data) {
                toast.success("Pedido realizado com sucesso!");                                 
                EnviarPedidoWhatsApp(response.data.pedido_id, 
                                     data, 
                                     loja.url_loja,
                                     loja.whatsapp);
                setCart([]);
                localStorage.removeItem(`sessionCart${loja.id}`);
                window.location.href = '/'+loja.url_loja;
            } else {
                console.log(response);
            }

        })
        .catch(err =>{ 
            console.log(err)  
        });
        

    }
    
   
    useEffect(()=>{
            
        if (cart.length == 0) {            
            window.location.href = '/'+loja.url_loja;
        }
    }, [cart])

    useEffect(() => {       


        api.get(`api/v1/especies-pag/${loja.id}`)
        .then(response =>{ 
            setEspeciesPag(response.data);                         
        })
        .catch(err =>{ 
            console.log(err)  
        });
    },[loja])

    useEffect(()=>{
        SelecionarTipoEntrega(0);
    },[])

    useEffect(()=>{
        if (especiesPag.length > 0) { 
            setTipoPagId(especiesPag[0].id);
            setTipoPagNome(especiesPag[0].nome);
        }
    },[especiesPag])

    return <div className="container-fluid mt-page">
        <NavBar/>

        <div className="row col-xl-6 offset-xl-3 col-lg-8 offset-lg-2  resumo-pedido rounded">
            <div>
                <h2 className="mt-2">Finalizar Pedido</h2>   
            </div>
            <div className="row mt-3">            
            {                
                cart ?
                cart.map((prod) => {
                    return <div key={prod.id_carrinho}>
                        <Produto    nome= {prod.nome}
                                    valor_total={prod.vl_unit * prod.qtde}
                                    qtde={prod.qtde}
                                    imagem={prod.imagem}
                                    valor_unit={prod.vl_unit}
                                    id_carrinho={prod.id_carrinho}
                                    onClickRemover={RemoveItemCart}
                                    />
                    </div> 
                }) :
                null            
            }

            </div>
            <div className='row align-items-end'>               
                    <div className='col-12 d-flex justify-content-between align-items-center mt-3 border-bottom'>
                        <h3>Total:</h3>
                        <h3 className='me-3'>{totalCart.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</h3>
                    </div>                                    
                    <div className="mt-5 mb-3">
                        <h4>Informações do Pedido</h4>                                            
                    </div>

                    <div className="row">
                        <div className="d-block mb-4 col-lg-5 ">
                            <small className="me-2"><b>Tipo de entrega: </b></small>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadioRetirada" value="opRetirada" onClick={(e) => SelecionarTipoEntrega(0)}/>
                                <label className="form-check-label" htmlFor="inlineRadioRetirada">Retirada</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadioEntrega" value="opEntrega" onClick={(e) => SelecionarTipoEntrega(1)}/>
                                <label className="form-check-label" htmlFor="inlineRadioEntrega">Entrega</label>
                            </div>
                        </div>
                        <div className=" mb-4 d-flex justify-content-between align-items-top col-lg-7">
                            <div className="d-block col-lg-4 col-sm-4">
                                <small className="me-2 d-inline"><b>Forma pagamento:</b></small>
                            </div>
                            <div className="col-lg-8 col-sm-8">
                                <select className="form-select d-inline" aria-label="Default select example" id="selectEspeciePag" onChange={(e)=> SelecionarEspeciePag()}>
                                    {
                                        especiesPag.map(ep => {
                                        return <option key={ep.id} value={ep.id} >{ep.nome}</option>
                                        })                        
                                    }
                                </select>
                            </div>                    
                        </div>
                    </div>

                    
                    <div className="form-floating mb-3">
                        <input type="text" onChange={(e)=> setClienteNome(e.target.value)} className="form-control" id="floatingInput" placeholder="Nome Completo"/>
                        <label className="ms-2" htmlFor="floatingInput">Nome completo</label>
                    </div>
                    
                    
                        <div className="form-floating mb-3 col-xl-6 col-md-12">
                            <InputMask  type="tel" onChange={(e)=> setClienteTelefone(e.target.value)} mask="99 99999-9999" maskChar={null} className="form-control" id="floatingInput" placeholder="Celular/WhatsApp"/>
                            <label className="ms-2" htmlFor="floatingInput">Celular/WhatsApp</label>
                        </div>


                        <div className="form-floating mb-3 col-xl-6 col-md-12">
                            <input type="email" onChange={(e)=> setClienteEmail(e.target.value)} className="form-control" id="floatingInput" placeholder="Email"/>
                            <label className="ms-2" htmlFor="floatingInput">Email</label>
                        </div>                    
                    {
                        tipoEntrega == 1 ?
                        <div className="form-floating mb-3">
                            <input type="text" onChange={(e)=> setClienteEndereco(e.target.value)} className="form-control" id="floatingInput" placeholder="Endereço Completo"/>
                            <label className="ms-2" htmlFor="floatingInput">Endereço completo</label>
                        </div> : null
                    }

                    <div className="form-floating mb-3">
                        <textarea className="obsPedido form-control" onChange={(e)=> setObsPedido(e.target.value)} placeholder="Observações do pedido" id="floatingTextarea2"/>
                        <label className="ms-2" htmlFor="floatingTextarea2">Observações</label>
                    </div>

                    <div className="row mb-3 text-center">
                        <div className="col-1">
                            <i className="fas fa-circle-exclamation me-2 fa-3x"></i>
                        </div>
                        <div className="col-11">
                            <span>Após finalizar o pedido nós entraremos em contato para confirmação dos itens, da forma de pagamento e da retirada ou entrega</span> 
                        </div>
                    </div>

                     <div className="row justify-content-center ms-2 mb-5 ">                        
                            <button onClick={(e)=>FinalizarPedido()} className='btn btn-lg btn-success mt-4'>Finalizar</button>                             
                    </div>                       
                    
            </div>
            




        </div>
        
    </div>
}

export default Checkout;