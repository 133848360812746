import { useContext, useEffect, useLayoutEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { LojaContext } from "../../contexts/loja";
import NavBar from "../../components/navbar";
import Produto from "../../components/produto/lista";
import api from "../../services/api";
import Footer from "../../components/footer";
import './style.css';

function Home(){
    
    const {loja, setLoja} = useContext(LojaContext);
    const [searchParams] = useSearchParams();
    const descricao = searchParams.get('descr') ?? 'Produtos';
    const busca = searchParams.get('q') ?? '';
    var categoria_id = searchParams.get('cat') ?? '';
    const [pagina, setPagina] = useState(1);
    const [mais, setMais]  = useState(true);
    const [resultado, setResultado] = useState([]);
    const [processando, setProcessando] = useState(false);

    

    useEffect(()=>{         
        ListarProdutos(true);
    }, [loja, busca,categoria_id])   
    
    

    function ListarProdutos(indReset){
        let pg = 0;
        
        setProcessando(true);
 
        pg = indReset ? 1 : pagina + 1;

        if (loja.id > 0) {
            api.get(`api/v1/produtos/${loja.id}`,{
                params: {
                    categoria_id,
                    // produto_id,
                    busca : busca,
                    pagina : pg            
                }
            })
            .then(response =>{                                  
                if (indReset){ 
                    setResultado(response.data);                 
                    setPagina(1);
                } else {                
                    setResultado((oldArray) => [...oldArray, ...response.data]);          
                    setPagina((oldPagina)=> oldPagina + 1);
                }                
                setProcessando(false);            
                setMais(response.data.length >= 12); //12 é o padrão de envio da api                
            })
            .catch(err =>{                
                console.log(err)
                setProcessando(false);
            });    
        }    
    }


    return <>
        <div className="container-fluid mt-page">
            <NavBar loja_id = {loja.id}/>            

            <div className="row m-2">
                <h3>{descricao}</h3>
                {busca.length > 0? <small>Pesquisando por: {busca}</small> : null}            
            </div>
            
            <div className="row justify-content mt-2 m-2">   
                

                {     
                resultado.map(produto => {
                    return <Produto key={produto.id} 
                                    nome={produto.nome} 
                                    id={produto.estoque_id_one}
                                    imagem = {produto.imagem}
                                    valor = {produto.valor}
                                    produto_id={produto.id}  
                                    produto_one_id={produto.estoque_id_one}                                  
                                    />
                })                 
                }
                {                
                    !processando && resultado.length === 0  ?                    
                    <div className="text-center mt-2"> 
                        <i className="d-block fas fa-circle-exclamation fa-5x"></i>                           
                        <span className="d-block mt-3 text-secondary">Nenhum produto encontrado</span>
                    </div>: null
                }    
            </div>

            {    
            processando ? <div className="text-center m-5">
                <span className="spinner-grow spinner-grow-sm text-secondary me-2" role="status" ></span>
                <span className="text-secondary">Buscando produtos...</span>
            </div> : null
            }

            {

            !processando && mais ?

            <div className="row m-4">
                <button onClick={(e) => ListarProdutos(false)} className="btn btn-secondary">Ver mais produtos</button>


            </div> : null
            }

            <Footer/>
             
        </div>
        
        
    </>

}

export default Home;