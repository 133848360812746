import { useContext } from 'react';
import { Link } from 'react-router-dom';
import {LojaContext} from '../../contexts/loja';
import './style.css';
import {getCurrentDate, getCurrentHour} from '../../utils';


function Footer(){
    const {loja} = useContext(LojaContext);
    const ano = new Date().getFullYear();
        

    function AbrirWhatsApp(){
            
        var texto = `👋 Olá, venho do seu catálogo digital\n`+
                    `Gostaria de mais informações sobre os seus produtos/serviços`                                    
        texto = window.encodeURIComponent(texto);
        window.open(`whatsapp://send?phone=55${loja.whatsapp}&text=${texto}`, "_blank")                        
    }



    
    return <>
    <div className='row border-top mt-5 footerDiv'>
        <div className=' col-12 mt-4 ms-3 mb-3 align-items-center text-center'>                        
            {
                loja.endereco == undefined || loja.endereco == null || loja.endereco == '' ? null :
                <small className='d-block'><i className='fas fa-location-dot me-2'></i>{loja.endereco}</small>
            }

            {
                loja.telefone == undefined || loja.telefone == null || loja.telefone == '' ? null :   
                <small className='d-block'><i className='fas fa-phone me-2'></i>{loja.telefone}</small> 
            }

            {
                loja.email == undefined || loja.email == null || loja.email == '' ? null :       
                <small className='d-block'><i className='fas fa-at me-2'></i>{loja.email}</small>
            }
                
                
                
                <div className='mt-2'>                    
                    {
                        loja.whatsapp == undefined || loja.whatsapp == null || loja.whatsapp == '' ? null :
                        <a target="_blank" onClick={AbrirWhatsApp} href='#' className='d-inline text-secondary'><i className="fa-brands fa-whatsapp fa-2x link-rodape"></i></a>
                    }
                    {
                        loja.instagram == undefined || loja.instagram == null || loja.instagram == '' ? null :
                        <a target="_blank" href={`https://www.instagram.com/${loja.instagram}`} className='d-inline ms-3 text-secondary'><i className="fa-brands fa-instagram fa-2x link-rodape"></i></a>                
                    }
                    {
                        loja.facebook == undefined || loja.facebook == null || loja.facebook == '' ? null :
                        <a target="_blank" href={`https://www.facebook.com/${loja.facebook}`} className='d-inline ms-3 text-secondary'><i className="fa-brands fa-facebook fa-2x link-rodape"></i></a>
                    }                                                    
                </div>
                
        </div>

        <div className='col-12 mt-3 align-items-center text-center border-top'>
            <div className='col-12 mt-2 p-2'>            
                <small className='  copyright text-secondary'> Copyright © {ano} One Sistema Comercial - Todos os direitos reservados. </small>
            </div>
        </div>

        
    </div>
</>    

}

export default Footer;