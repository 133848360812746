import { useContext, useEffect, useLayoutEffect, useState } from "react";
import './style.css';
import api from '../../services/api';
import Logo from '../../assets/logo.png';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { LojaContext } from '../../contexts/loja';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';

function NavBar(props){
    const {url_loja} = useParams();
    const {loja, setLoja, cart, totalCart, urlLojaState, setUrlLojaState, urlServidor } = useContext(LojaContext);

    useLayoutEffect(() => { 
      
            api.get(`api/v1/lojas/${url_loja}`)
            .then(response =>{        
                setLoja(response.data[0]);
                if (urlLojaState == undefined || urlLojaState == '') {
                    setUrlLojaState(response.data[0].url_loja);
                };
            })
            .catch(err =>{
                if (err.response.status == 404) {
                    // alert('Loja não encontrada!');
                    toast.error("Loja não encontrada!"); 
                    navigate('/');
                } else {
                    console.log(err.response)
                }                        
            }); 
    },[])



function openSidebar(){
    const event = new CustomEvent('openSidebar');    
    window.dispatchEvent(event);
}


const navigate = useNavigate();    
const [categorias, setCategorias] = useState([]);
const [busca, setBusca] = useState('');


function buscar(){
    navigate(`?q=${busca}&descr=Busca`);
}
    
useEffect(() => {       
    if (loja.id != undefined && loja.id != '' && loja.id != null){
        api.get(`api/v1/categorias/${loja.id}`)
        .then(response =>{ 
            setCategorias(response.data);                   
        })
        .catch(err =>{ 
            console.log(err)  
        });
}
},[loja])

document.title = 'Catálogo '+ (loja.fantasia ?? 'One');

return <nav className="navbar sticky-md-top navbar-expand-lg navbar-light bg-light ps3 pe3">
    <div className="container-fluid">
        <Link className="navbar-brand" to={'/'+loja.url_loja}>             
            {
                loja.logo != '' && loja.logo != undefined && loja.logo != null ? 
                <img className="imgLogoNav" src={urlServidor+loja.logo}  alt={loja.fantasia}/> 
                : <span className="align-middle"><strong>{loja.fantasia}</strong></span>
            }                                                    
        </Link>
        <div className="d-none d-lg-block">
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" 
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
        </button>
        </div>
    
        <div className='collapse navbar-collapse d-block d-lg-none' id='navbarSupportedContent'> 
            <div className='ms-auto me-3 mt-1'>        
                
                <div className="input-group">
                    <input onChange={(e)=>setBusca(e.target.value)} type="search" className="form-control" placeholder="O que você procura?" aria-label="Search" />
                    <button onClick={(e) => buscar()} className="btn btn-outline-secondary" type="button" id="button-addon2"><i className='fas fa-search'></i></button>
                </div>

            </div>
            <div className='mt-1'>                                                     
                <div className="btn-group me-3">
                    <button className="btn btn-outline-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                    Categorias<i className="ms-2 fas fa-border-all"></i>
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1"> 
                        {                            
                        categorias.map(categoria =>{                                                                                  
                            return<li key={categoria.id}>
                                <div className="d-grid gap-2">
                                    <button className='btn btn-outline-secondary border-0' onClick={(e)=> navigate(`?cat=${categoria.grupo_id_one}&descr=${categoria.nome}`)}>{categoria.nome}</button>
                                </div>
                                </li>                                                         
                        })                                            
                        }                        
                    </ul>
                
                    <div className="ms-1">
                    {totalCart > 0 ? 
                        <button onClick={openSidebar} type="button" className=" btn btn-success  me-3">
                            <i className='fas fa-shopping-bag pe-2'></i>
                            {totalCart.toLocaleString('pt-br',{style : 'currency', currency: 'BRL'})}
                            {/* <b>{totalCart.toLocaleString('pt-br',style: 'currency', currency: 'BRL')}</b> */}
                        </button>    
                        :
                        <button onClick={openSidebar} type="button" className=" btn btn-outline-secondary  me-3">
                            <i className='fas fa-shopping-bag pe-2'></i>
                            <b>{totalCart.toLocaleString('pt-br',{style : 'currency', currency: 'BRL'})}</b>
                        </button>                         
                    }

                    </div>
                </div>
            </div>
        </div>
        {/* <div className="d-block d-lg-none ms-1">
                <button onClick={openSidebar} type="button" className=" btn btn-outline-secondary  me-1">
                    <i className='fas fa-shopping-bag pe-2'>                    
                    
                        </i> {totalCart > 0 ? totalCart.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) : 'R$ 0,00' }                            
                </button>    
        </div> */}
        
    </div>
    
    
    

</nav>

}

export default NavBar;