import './style.css';
import LogoOne from '../../assets/logo-one.png';
import MockupSistema from '../../assets/mockup-site.png'

function Raiz(){

    function LojaDemo(){
        window.location.href = '/sportshopping';
    }

    function AbrirWhats(){
        window.open(`https://api.whatsapp.com/send?phone=559236731330&text=Ol%C3%A1!!%20Venho%20do%20site%20de%20apresenta%C3%A7%C3%A3o%20do%20cat%C3%A1logo%20digital.%20Gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20sistema.`, "_blank")
    }
    
    return  <div className='container'>
                <div className="row d-flex content-justify align-items-center fundo-raiz">
                    <div className='row'>                        
                        <img className='imgLogoOne img-fluid mx-auto d-block m-5' src={LogoOne} alt="LogoOne" />                
                    </div>    
                    <div className='row'>                        
                        <div className='col-lg-6'>
                            <h1 className='desc-site'>Catálogo online de produtos com pedidos via WhatsApp e Email</h1>
                        </div>
                        <div className='col-lg-6 text-center d-flex justify-content-center align-top'>
                            <img className='img-fluid img-morckup' src={MockupSistema} alt="print" />   
                        </div>                    
                    </div>  

                    <div class="d-grid gap-2 col-6 offset-3 mb-3">
                        <button onClick={LojaDemo} class="btn btn-primary btn-lg" type="button"> <i className='fas fa-magnifying-glass'></i> Loja de Demonstração</button>
                        <button onClick={AbrirWhats} class="btn btn-success btn-lg " type="button"><i className='fa-brands fa-whatsapp'></i> Fale Conosco</button>
                     </div>                          
                </div>                                                                                
            </div>
}

export default Raiz;