import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dock } from 'react-dock';
import { LojaContext } from '../../contexts/loja';
import Produto from '../produto/sacola';
import './style.css';


function Sidebar(){
    
    const navigate = useNavigate();
         
    const [show, setShow] = useState(false);
    const {loja, cart, setCart, totalCart, RemoveItemCart, urlLojaState} = useContext(LojaContext);
    const [largDock, setLargDock] = useState(0);

    function LarguraDock(){
        var largura = window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth;         

        if (largura <= 600) {
            setLargDock(largura * 0.8);
        } else 
        if (largura <= 990) {
            setLargDock(largura * 0.6); 
        } else {
            setLargDock(largura * 0.35);

        }                
    }


    useEffect(()=>{
        LarguraDock();
        window.addEventListener('openSidebar', ()=>{
            setShow(true);
        }); 
    }, [])

    function FinalizarPedido(){        
        setShow(false);    
        navigate(`/${urlLojaState}/checkout`);
        // navigate(`/checkout`);
        
    }


    return <Dock position='right'
                 isVisible={show} 
                 fluid={false}
                 size={largDock}                                 
                 onVisibleChange={(visible) => {
                 setShow(visible);
                 }}>
        

        { 
            cart.length == 0 ? <div className='d-flex h-100 flex-column justify-content-center aligh-items-center text-center'>
                <i className="d-block fas fa-shopping-bag fa-5x text-secondary"></i>                           
                <span className="d-block mt-3 text-secondary">Sua sacola está vazia</span>    
            </div> 
            :
            <div className='container-fluid h-100 pt-4 sidebar'>
                <div className='d-flex justify-content-start align-items-center'>
                    <button onClick={(e) => setShow(false)} className='btn'><i className='fas fa-xmark'/></button>
                    <h5>Minha Sacola</h5>
                </div>
                <div className='row produtos'>
                    {
                        cart.map((prod)=> {
                            return <Produto key={prod.id_carrinho}
                                            nome= {prod.nome}
                                            valor_total={prod.vl_unit * prod.qtde}
                                            qtde={prod.qtde}
                                            imagem={prod.imagem}
                                            valor_unit={prod.vl_unit}
                                            id_carrinho={prod.id_carrinho}
                                            onClickRemover={RemoveItemCart}
                                            />
                        })
                    }
                </div>

                <div className='row align-items-end footer'>
                    {/* <div className='col-12 d-flex justify-content-between align-items-center mt-2'>
                        <span>Subtotal</span>
                        <span>R$ 45,00</span>
                    </div>
                            
                    <div className='col-12 d-flex justify-content-between align-items-center mt-2'>
                        <span>Taxa de entrega</span>
                        <span>R$ 5,00</span>
                    </div> */}


                
                    <div className='col-12 d-flex justify-content-between align-items-center mt-3'>
                        <b>Total</b>
                        <h3 className='me-3'>{totalCart.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</h3>
                    </div>
                
                    <button onClick={(e)=>FinalizarPedido()} className='btn btn-lg btn-success rounded-0 align-items-center btn-pedido'>Finalizar</button>
                </div>




            </div>
        }
    </Dock>

}

export default Sidebar;
