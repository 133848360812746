import { useContext } from 'react';
import { LojaContext } from '../../../contexts/loja';
import './style.css';


function Produto(props){
    const {AddItemCart, urlServidor} = useContext(LojaContext);

    return <div className='col-12'>
        <div className='row p-3 ps-0 border-bottom'>
            <div className='col-3'>

                {                                      
                    props.imagem == undefined || props.imagem == null || props.imagem == ''  ?  
                    <img className="img-fluid rounded imgProdutoSacola" src={process.env.PUBLIC_URL + "/sem_imagem.png"} alt=""/>
                    : <img className="img-fluid rounded imgProdutoSacola" src={urlServidor+props.imagem} alt=""/>
                }
            </div>
            <div className='col-9'>
                <div className='d-flex justify-content-between'>
                    <small className='me-1'>
                                        
                        <b>{props.nome}</b>
                    </small>
                    <small>
                        <b>
                        {props.valor_total.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}                            
                        </b>
                    </small>                    
                </div>
                <small className='d-block'>{props.qtde.toLocaleString('pt-BR', {minimumIntegerDigits:2})} x {props.valor_unit.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</small>
                {
                    props.onClickRemover ?
                        <button onClick={(e) => props.onClickRemover(props.id_carrinho)} className='btn btn-sm btn-outline-danger mt-2'>Remover</button>
                    : null
                }

            </div>           
        </div>

    </div>
}

export default Produto